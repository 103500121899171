//jQuery
import jquery from 'jquery';

window.$ = jquery;
window.jQuery = jquery;

import 'bootstrap';

 //Vieの完全ビルド版を使うためにpackage.jsonに記述
 //"browser": {"vue": "vue/dist/vue.common.js"}

import Vue from 'vue';
window.Vue = Vue;

//import App from './App.vue';

//import BootstrapVue from 'bootstrap-vue'; 
//import 'bootstrap/dist/css/bootstrap.css'; // CSSに書く
//import 'bootstrap-vue/dist/bootstrap-vue.css'; // CSSに書く
//Vue.use(BootstrapVue); 
Vue.config.productionTip = false;
Vue.config.devtools = 'source-map';

//デリミタを変更 （Twig のデリミタと衝突するため）
// Vue.options.delimiters = ['##', '##'];

//DateTimeピッカー VueCtkDateTimePicker
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate);

window.validators = require('vuelidate/lib/validators');

import axios from 'axios';

window.axios = axios;


//Vuejs Pagenate  ajaxでのページネーションに使用する
import Paginate from 'vuejs-paginate';
Vue.component('paginate', Paginate);
